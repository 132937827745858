import React from "react";
import './App.css';
import RoutesApp from './RoutesApp';
import { BrowserRouter } from "react-router-dom";
import { AuthContext } from './context/AuthProvider';

import FooterMenu from './layouts/FooterMenu';
import Drawer from './layouts/Drawer';
import { localStorageKey } from './constants/LocalStorageKey';
import CircularProgress from '@mui/material/CircularProgress';
import { WithAxios } from "./axios-api-kit/api-kit";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import axiosService from "./axios-api-kit/axiosService";
import { appTheme } from "./theme/colorTheme";
import { addListener, launch } from 'devtools-detector';
import { useDispatch } from 'react-redux';
import { setUserInfo, setCommonInfo, setHelpLineWhatsAppNumber, setCommonHelpline, setWithdrawalStatus, setNewsData, setPaymentHelplineData, setMyIdData } from "./utils/appSlice";
import runOneSignal from "./OneSignal";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const tourAppState = {
  status:'',
  run: false,
  stepIndex: 0,
  steps: [
    {
      content: 'Add your bank account for withdrawl to your bank',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightPadding: 15,
      styles: {
        options: {
          zIndex: 1,
        }
      },
      target: '#withdrawalDetails',
      title: 'Withdrawal details',
    },
    {
      content: 'You can check your notification from here',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightPadding: 15,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: '#notification',
      title: 'Notification',
    },
    {
      content: 'your wallet balance will show here',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightPadding: 40,
      styles: {
        options: {
          zIndex: 1,
        },
      },
      target: '#yourWallet',
      title: 'Your wallet',
    },
    {
      content: 'you can deposit money to your wallet from here',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightPadding: 40,
      styles: {
        options: {
          zIndex: 1,
        },
      },
      target: '#depositWallet',
      title: 'Deposit wallet',
    },
    {
      content: 'You can withdraw your money to bank from here',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightPadding: 40,
      styles: {
        options: {
          zIndex: 1,
        },
      },
      target: '#withdrawWallet',
      title: 'Withdraw wallet',
    },
    {
      content: "Let's begin your joureny by creating your first ID",
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightPadding: 50,
      styles: {
        options: {
          zIndex: 1,
        },
      },
      target: '#createIds',
      title: 'Create Ids',
    },
    {
      content: 'You can come to home page from here anytime',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'top',
      spotlightPadding: 40,
      styles: {
        options: {
          zIndex: 1,
        },
      },
      target: '#homeTab',
      title: 'You are here',
    },
    {
      content: 'You can check trasnaction history from here',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'top',
      spotlightPadding: 40,
      styles: {
        options: {
          zIndex: 1,
        },
      },
      target: '#passbookTab',
      title: 'Passbook',
    },
    {
      content: "Check your existing ID's and create new ID's from here",
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'top',
      spotlightPadding: 40,
      styles: {
        options: {
          zIndex: 1,
        },
      },
      target: '#idsTab',
      title: 'Ids',
    },
    {
      content: 'Let create your first id, select any exchange out of list',
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'top',
      spotlightPadding: 40,
      styles: {
        options: {
          zIndex: 1,
        },
      },
      target: '#firstIdCreation0',
      title: 'Create your first Id',
    },
  ],
  tourActive: false,
};
function App() {
  const [currentPageName, setCurrentPageName] = React.useState('');
  const [joyTourState, setJoyTourState] = React.useState(tourAppState);
  const dispatchAction = useDispatch();
  const intialAppDataState = {
    isLoading: true,
    apiToken: null,
    notification: false,
    notificationText: 'Something went wrong!',
    createIdData: null,
    paymentData: null,
    depositOrWithdrawIdData: null,
    isDevToolOpen:false
  };

  const setCommonInfoFn = () => {
    axiosService.getCommonConfigs().then((response => {
      dispatchAction(setCommonInfo(response?.data?.common_data[0]?.value));
      dispatchAction(setCommonHelpline(response?.data?.helpline_data[0]?.value));
      dispatchAction(setWithdrawalStatus(response?.data?.withdraw_data[0]?.value));
      dispatchAction(setNewsData(response?.data?.news_data[0]?.value));
      dispatchAction(setPaymentHelplineData(response?.data?.payment_helpline_data[0]?.value));
    })).catch((error) => {
      
    })
  };

 

  const getUser = React.useCallback((userObj) => {
    if(!userObj){
      axiosService.getUser().then((response) => {
        dispatchAction(setUserInfo(response?.data));
      }).catch((error) => {
      })
    }else{
      dispatchAction(setUserInfo(userObj));
    }
  }, []);

  const setCommonHelplineInfo = () => {
    axiosService.getHelpConfigs().then((data => {
      dispatchAction(setHelpLineWhatsAppNumber(data?.data))
    })).catch((error) => {
      
    })
  }

 

  const loginReducer = (prevState, action) => {
    switch (action.type) {
      case 'RETRIEVE_TOKEN':
        return {
          ...prevState,
          apiToken: action.token,
          isLoading: false,
          notification: false,
        };
      case 'LOGIN':
        return {
          ...prevState,
          apiToken: action.token,
          isLoading: false,
          notification: false,
        };
      case 'LOGOUT':
        return {
          ...prevState,
          apiToken: null,
          isLoading: false,
          notification: false,
        };
      case 'SIGNUP':
        return {
          ...prevState,
          apiToken: action.token,
          isLoading: false,
          notification: false,
        };
      case 'LOADING':
        return {
          ...prevState,
          isLoading: action.loading,
        };
      case 'NOTIFICATION':
        return {
          ...prevState,
          notification: action.notification,
          notificationText: action.notificationText
        };
      case 'DEVTOOLEVENT':
        return {
          ...prevState,
          isDevToolOpen:action.isDevtoolOpen
        };
      default: return {
        ...prevState
      }
    }
  };
   
  const [appDataState, dispatch] = React.useReducer(loginReducer, intialAppDataState);
  const authContext = React.useMemo(() => {
    return {
      signIn: (foundUser) => {
        const api_token = foundUser.api_token;
        try {
          localStorage.setItem(localStorageKey.APITOKEN, api_token);
          getUser(foundUser);
          setCommonInfoFn();
        } catch (e) {
        }
        dispatch({ type: 'LOGIN', token: api_token });
      },
      signUp: (foundUser) => {
        const api_token = foundUser.api_token;
        try {
          localStorage.setItem(localStorageKey.APITOKEN, api_token);
          getUser(foundUser);
          setCommonInfoFn();
        } catch (e) {
        }
        dispatch({ type: 'SIGNUP', token: api_token });
      },
      signOut: () => {
        try {
          const app_push_id = localStorage.getItem(localStorageKey.PUSHID);
          localStorage.clear();
          sessionStorage.clear();
          !!app_push_id && localStorage.setItem(localStorageKey.PUSHID, app_push_id);
          runOneSignal();
        } catch (e) {
        }
        dispatch({ type: 'LOGOUT' });
      },
      loadingState: (loadingState) => {
        dispatch({ type: 'LOADING', loading: loadingState });
      },
      showNotification: (isError, message) => {
        dispatch({ type: 'NOTIFICATION', notification: isError, notificationText: !!message ? message : 'Something went wrong!' });
      },
      setCreateIdData: (createIdData) => {
        localStorage.setItem(localStorageKey.SETCREATEIDDATA, JSON.stringify(createIdData));
      },
      setPaymentData: (paymentData) => {
        localStorage.setItem(localStorageKey.SETPAYMENTDATA, JSON.stringify(paymentData));
      },
      setDepositOrWithdrawIdData: (depositOrWithdrawIdData) => {
        localStorage.setItem(localStorageKey.SETDEPOSITORWITHDRAWIDDATA, JSON.stringify(depositOrWithdrawIdData));
      },
      getCreateIdState: () => {
        return JSON.parse(localStorage.getItem(localStorageKey.SETCREATEIDDATA));
      },
      getPaymentData: () => {
        return JSON.parse(localStorage.getItem(localStorageKey.SETPAYMENTDATA));
      },
      getDepositOrWithdrawIdData: () => {
        return JSON.parse(localStorage.getItem(localStorageKey.SETDEPOSITORWITHDRAWIDDATA));
      },
      getUpdatedUser: () => {
        getUser();
      },
      setCurrentPageName: () => {
        setCurrentPageName(window.location.pathname);
      },
      getDevToolState:() => {
        return appDataState.isDevToolOpen;
      },
      joyTour: joyTourState,
      setJoyTour:setJoyTourState,
    };
  }, [appDataState.isDevToolOpen, setJoyTourState, joyTourState]);

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
      localStorage.setItem(localStorageKey.ISJOYTOUROPEN, JSON.stringify(true));
      setJoyTourState((prvState) => {
        return {
          ...prvState,
          status:"CLOSED",
          run: false,
        }
      });
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (index === 0) {
        setTimeout(() => {
          setJoyTourState((prvState) => {
            return {
              ...prvState,
              run:true,
              stepIndex: nextStepIndex,
            }
          });
        }, 300);
      } else if (index === 1) {
        setJoyTourState((prvState) => {
          return {
            ...prvState, 
            run: false,
            stepIndex: nextStepIndex,
          }
        });

        setTimeout(() => {
          setJoyTourState((prvState) => {
            return {
              ...prvState,
              run:true,
            }
          });
        }, 300);
      } else if (index === 2 && action === ACTIONS.PREV) {
        setJoyTourState((prvState) => {
          return {
            ...prvState,
            run: false,
          stepIndex: nextStepIndex,
          }
        });

        setTimeout(() => {
          setJoyTourState((prvState) => {
            return {
              ...prvState,
              run:true,
            }
          });
        }, 300);
      } else {
        // Update state to advance the tour
        setJoyTourState((prvState) => {
          return {
            ...prvState,
            stepIndex: nextStepIndex,
          }
        });
      }
    }
  };



  React.useEffect(() => {
    runOneSignal();
    setCommonHelplineInfo();
    //devtools event capture start here
    const view = document.createElement('div');
    document.body.appendChild(view);
    addListener(isOpen => {
      if (isOpen) {
        localStorage.setItem(localStorageKey.ISDEVTOOLOPEN, JSON.stringify(true))
        dispatch({ type: 'DEVTOOLEVENT', isDevtoolOpen: true });
      } else {
        localStorage.setItem(localStorageKey.ISDEVTOOLOPEN, JSON.stringify(false))
        dispatch({ type: 'DEVTOOLEVENT', isDevtoolOpen: false });
      }
    }
    );
    launch();
 //devtools event capture end here 
    
    let api_token = null;
    try {
      api_token = localStorage.getItem(localStorageKey.APITOKEN);
      if(api_token){
        getUser(); 
        setCommonInfoFn(); 
      } 
    } catch (e) {
    }
    dispatch({ type: 'RETRIEVE_TOKEN', token: api_token });
  }, []);

  return (
    
      <div className="App">
      {appDataState.isLoading && <Backdrop
        sx={{ color: appTheme.color1, zIndex: (theme) => theme.zIndex.drawer + 99999999 }}
        open={true}
      >
        <CircularProgress sx={{color:`${appTheme.color12}`}} />
      </Backdrop>}
      <Snackbar open={appDataState.notification} autoHideDuration={5000} onClose={() => {
        dispatch({ type: 'NOTIFICATION', notification: false });
      }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity="error" onClose={() => {
          dispatch({ type: 'NOTIFICATION', notification: false });
        }}>
          {appDataState.notificationText}
        </Alert>
      </Snackbar>
      <AuthContext.Provider value={authContext}>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={joyTourState.run}
        scrollToFirstStep
        showProgress
        showSkipButton
        stepIndex={joyTourState.stepIndex}
        steps={joyTourState.steps}
        styles={{
          options: {
            width:'250px',
            padding:0
          },
          spotlight: {
            // Set your border-radius here!
            borderRadius: 100,
          },
          buttonNext: {
              background: appTheme.color12,
              outline: 'none',
              color: appTheme.color1
          },
      }}
      />
        <WithAxios>
          <div style={{ maxWidth: currentPageName === '/' ? '1000px':'600px', margin: '0 auto',}}>
          <BrowserRouter>
            {!!appDataState.apiToken && window.location.pathname !== '/paymentGatewayCheckout' && 
            window.location.pathname !== '/transactionStatus'? <Drawer /> : null}
            <RoutesApp />
            {!!appDataState.apiToken && window.location.pathname !== '/paymentGatewayCheckout' &&
            window.location.pathname !== '/transactionStatus' ? <FooterMenu /> : null}
          </BrowserRouter>
          </div>
         
          
        </WithAxios>

      </AuthContext.Provider>
    </div>
  );
}

export default App;
